.heroSection {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem 2rem;
  }
  
  .gridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    max-width: 1200px;
    gap: 1rem;
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
  }
  
  .profileImage {
    width: 100%;
    max-width: 350px;
    border-radius: 50%;
  }
  
  .textContainer {
    text-align: left;
  }
  
  .heading {
    font-size: 40px;
    font-family: EB Garamond, serif;

    font-weight: 200;
  }
  
  .paragraph {
    font-size: 22px;
    font-family: Playfair Display, serif;
    font-weight: 200;

  }
  
  .readMore {
    display: inline-block;
    margin-top: 1rem;
    text-decoration: none;
    font-weight: bold;
    color: black;
    border-bottom: 2px solid black;
    transition: color 0.3s ease;
  }
  
  .readMore:hover {
    color: gray;
  }
  
  @media (max-width: 768px) {
    .gridContainer {
      grid-template-columns: 1fr;
      text-align: center;
    }
  
    .imageContainer {
      order: -1;
    }
  
    .profileImage {
      max-width: 200px;
    }
  }