@import url('https://fonts.googleapis.com/css2?family=Arapey&family=GFS+Didot:wght@400;500;600;700;800&family=Playfair+Display:wght@400;500;600;700;800&family=EB+Garamond:wght@400;500;600;700;800&display=swap');

/* Ensure fonts are properly loaded with font-display swap */
* {
  font-display: swap;
  margin: 0; /* Remove default margins for all elements */
  padding: 0; /* Remove default paddings for all elements */
  box-sizing: border-box; /* Prevent elements from exceeding their boundaries */
}

/* Main content styles */
.main-content {
  font-family: "Playfair Display", serif;
  padding-top: 50px; /* Aligns with AppBar height */
  background-color: #ffffff;
  position: relative; /* Positions it properly without scrolling issues */
  z-index: 1; /* Keeps it below higher layers like AppBar */
  overflow: hidden; /* Prevent horizontal scrolling within the content */
}

/* Ensure smooth scrolling experience */
html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
  background-color: #ffffff; /* Uniform background color */
  margin: 0; /* Reset default margin */
  padding: 0; /* Reset default padding */
  padding-top: 60px; /* Prevent content from going under navbar */
  font-weight: 200;
  height: 100%; /* Ensure full height */
}

/* Normalize AppBar */
.AppBar {
  margin: 0 auto;
  padding: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

/* Ensure articles are fully visible on mobile */
.container {
  max-width: 100%;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-break: break-word;
}

/* Make sure text elements don't get cut off */
p, h1, h2, h3, h4, h5, h6 {
  max-width: 90%;
  margin: 10px auto;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  p, h1, h2, h3, h4, h5, h6 {
    max-width: 100%;
    font-size: 90%;
  }
}

/* Blog content wrapper */
.blog-content {
  max-width: 95%;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  text-align: left;
  overflow-x: hidden;
}

/* Ensure images scale properly */
img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
}

/* Prevent iframe from overflowing */
iframe {
  max-width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

/* Ad container */
.ad-container {
  width: 100%;
  max-width: 728px;
  padding: 0;
  margin: 1rem auto;
}

/* Card structure */
.card {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}