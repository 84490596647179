.quiz2-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  margin: auto; /* Ensure it's centered */
  height: auto;
  text-align: center;
  background-color: #ffffff;
  padding: 1rem ;
 

}

.quiz2-question {
  margin: 1rem auto;
  font-size: 24px;
  font-family: "Playfair Display", serif;
  font-weight: 200;
  color: #745b4f;
  text-align: center;
  max-width: 400px; /* Ensure it doesn’t go full width */
}

.quiz2-answer-section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  max-width: 500px; /* Ensure same width as Quiz2 */
}

.quiz2-answer-button {
  width: 100%;
  max-width: 400px; /* Ensure buttons are same width */
  height: auto;
  border: 1px solid #ffffff;
  border-radius: 50px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  color: #ffffff;
  font-family: "Playfair Display", serif;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.quiz2-answer-button:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.25);
}

.quiz2-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  text-align: center;
  padding: 20px;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 650px) {
  .quiz2-answer-button {
    width: 90%;
  }

  .quiz2-container {
    margin: 1rem auto;
    padding: 0;
  }
}
