/* Container for the entire quiz */
.quizselection-container {
  text-align: center;
  padding: 10px;
}

/* Styling for the question header */
.quizselection-question h2 {
  margin-bottom: 1rem;
}

/* Grid container for the quiz options */
.quizselection-answer-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Default: 3 columns */
  gap: 1rem; /* Space between grid items */
  max-width: 1000px; /* Optional: constrain width */
  margin: 0 auto;
}

/* Style for each quiz option button */
.quizselection-answer-button {
  background-size: cover;
  width: 100%;
  background-position: center;
  height: 300px; /* Adjust height as needed */
  border: none;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6);
  transition: transform 0.2s ease;
}

/* Add hover effect */
.quizselection-answer-button:hover {
  transform: scale(1.05);
}

/* Responsive layout: stack vertically on small screens */
@media (max-width: 768px) {
  .quizselection-answer-section {
      grid-template-columns: 1fr; /* Single column layout */
  }
}

/* Stack in two columns on medium screens */
@media (max-width: 1024px) and (min-width: 769px) {
  .quizselection-answer-section {
      grid-template-columns: repeat(2, 1fr); /* Two columns */
  }
}

/* Results section styling */
.quizselection-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 800px;
  text-align: center;
  padding: 1rem;
  border-radius: 15px;
  background-color: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}