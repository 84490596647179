.rotating-text-container {
  padding: auto;
  margin: 1rem;
  margin-bottom: 0;
  background-color: #ffffff;
  overflow: hidden;
  font-family: 'Playfair Display', serif;
  font-weight: 200;
  white-space: nowrap;
}

.rotating-text {
  background-color: #ffffff;

  color: #745B4F;
  display: inline-block;
  animation: rotate-text 100s linear infinite;
  letter-spacing: 0.1em; /* Increases spacing between all characters */
  font-size: 1rem; /* Default font size */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .rotating-text {
    font-size: 14px; /* Adjust font size for medium screens */
  }
}

@media (max-width: 480px) {
  .rotating-text {
    font-size: 11px; /* Adjust font size for medium screens */
  }
}

@keyframes rotate-text {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}
