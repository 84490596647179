.heroSectionA {
    text-align: center;
    padding: 2rem 0;
    font-family: 'Playfair Display', serif;
  }
  
  .heroLinkA {
    text-decoration: none;
    color: inherit;
    display: block;
  }
  
  .categoryA {
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 1rem;
    border-bottom: 1px solid #000;
    display: inline-block;
    padding-bottom: 0.5rem;
  }
  
  .imageContainerA {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .heroImageA {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .textContainerA {
    max-width: 800px;
    margin: 1.5rem auto 0;
    text-align: center;
  }
  
  .titleA {
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.3;
    margin-bottom: 1rem;
  }
  
  .subtitleA {
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
  }
  
  .authorA {
    font-size: 0.85rem;
    font-weight: bold;
    letter-spacing: 1px;
    color: #333;
    text-transform: uppercase;
  }